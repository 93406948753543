@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-vault-interstitial-v2 {
  padding: 0;
  margin-right: @spacing-150;

  h2 {
    font-size: @spacing-200;
    font-weight: bold;
  }
  &__vasform {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__label {
    font-weight: bold;
    font-size: @spacing-200;
    line-height: 24px;
    margin-bottom: @spacing-50;
  }
  &__secondaryLabel {
    line-height: 20px;
  }
  &__tertiaryLabel {
    margin-bottom: @spacing-50;
  }
  &__err {
    display: none;
    .vas-messsage {
      // overide component style
      padding: @spacing-200 0;
    }
  }
  &__err.show {
    display: flex;
  }
  &__cta {
    margin: @spacing-400 0;
    button.btn {
      margin: @spacing-50 0;
    }
  }

  .vas-grading-interstitial-header {
    display: flex;
    justify-self: center;
    align-items: center;
    margin-bottom: 4px;

    &-title {
      color: var(--Foreground-Primary, #191919);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: var(--Family-Market-Sans, "Market Sans");
      font-size: var(--Size-Medium, 16px);
      font-style: normal;
      font-weight: 700;
      line-height: var(--Line-height-300, 24px); /* 150% */
      letter-spacing: var(--Letter-spacing-None, 0px);
      margin-left: 4px;
    }
  }

  .vas-interstitial-header {
    &-text {
      font-size: 1.25rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: auto;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border: none;
      background-color: var(--color-background-secondary);
      border-radius: 50%;

      svg.icon.icon--close-16 {
        fill: #111820;
      }
    }
  }

  .vas-adoption-interstitial-header {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;

    &-text {
      max-width: 100%;
      overflow: hidden;
      white-space: normal;
      line-height: 28px;
      text-align: center;
      font-size: 20px;
      margin: 12px 0;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border: none;
      background-color: var(--color-background-secondary);
      border-radius: 50%;

      svg.icon.icon--close-16 {
        fill: #111820;
      }
    }
  }

  .vas-interstitial-items {
    margin-bottom: @spacing-200;

    .vas-interstitial-item {
      display: flex;
      justify-content: space-between;

      &-left {
        width: 85%;
      }

      &-label {
        line-height: 24px;
        color: #000000;
        font-size: @spacing-200;
        margin-bottom: @spacing-50;
      }
      &-secondaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: @spacing-50;
      }
      &-tertiaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: 0.5rem;
      }
      &-quaternaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: 0.5rem;
      }
      .link-label {
        margin-top: 0.75rem;
      }
    }
  }

  .vas-body-heading {
    margin-top: @spacing-50;
    margin-bottom: @spacing-50;
  }

  .vas-heading {
    margin-top: @spacing-200;
    margin-bottom: @spacing-200;
    display: flex;
    align-items: center;
  }

  #vault-icon {
    display: inline-block;
    margin-right: @spacing-100 !important;
    svg {
      height: 16px;
      width: 16px;
    }
    padding-bottom: 2px;
  }

  .vas-interstitial-cards-mweb {
    margin-top: @spacing-150;
    margin-bottom: @spacing-300;
    display: flex;
    flex-direction: column;
    gap: @spacing-200;
  }

  .vas-card {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }

  .vas-card-icon {
    width: 24px;
    height: 24px;
  }

  .vas-card-title {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
  }

  .vas-card-description {
    margin: 6px 0 0;
    font-size: 0.875rem;
    color: var(--color-secondary-text, #6c757d);
  }

  .heading-text {
    margin-left: @spacing-50;
  }

  .vas-divider {
    border-top: 1px solid var(--color-background-tertiary);
    border-bottom: 1px solid var(--color-background-tertiary);
  }

  .vas-secondary-labels {
    margin-top: @spacing-200;
    margin-bottom: @spacing-200;
    .vas-interstitial-item-secondaryLabels a {
      color: var(--color-blue-500);
    }
  }

  .field {
    display: flex;
    align-items: center;
    .field__label {
      margin-left: @spacing-200;
    }
  }

  .vas-vault-item-card {
    .vas-itm-card {
      margin-left: -15px;
      .vas-itm-card__title {
        color: var(--color-foreground-primary);
        font-weight: @font-weight-regular;
      }
    }
    .vas-itm-card__image {
      width: 48px;
      height: 48px;
    }
  }

  .field-secondory-label {
    margin-left: @spacing-400;
    margin-bottom: @spacing-200;
  }

  .vault-interstitial-cta {
    margin-top: @spacing-100;
    float: right;
  }

  .spoke-body-primary {
    display: flex;
    margin-right: @spacing-50;
  }

  .vas-spoke-body-primary-left {
    flex: 1 1 auto;
  }

  .vas-interstitial-footer {
    margin-top: @spacing-200;
    &-cta {
      margin-top: @spacing-100;
      margin-bottom: @spacing-200;
    }
    &-section {
      margin-bottom: @spacing-200
    }
  }

  .lightbox-dialog__header {
    .icon-btn {
      display: none;
    }
  }

  .high-asp {
    margin-right: @spacing-50;
    .section-notice {
      display: block;
      .section-notice__header {
        height: auto;
      }
    }
  }

  .vas-interstitial-footer-mweb {
    &-cta {
      margin-top: 12px;
      margin-bottom: @spacing-200;
    }
    &-section {
      margin-bottom: 12px;
      .ux-textspans--INLINE_LINK {
        color: #707070;
      }
    }
  }

  .vas-interstitial-footer-btn {
    margin-top: var(--gap-large, 12px);
  }

  .vas-adoption-interstitial-header-img {
    width: 105px;
    height: 20px;
    flex-shrink: 0;
  }
}
